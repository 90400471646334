import React, {useEffect} from "react";

export default function AutoRenewAgreement(props) {

  useEffect(() => {
    document.title = "知源经络穴位自动续费服务协议"
  }, [])

  const alipayLink =
    <a style={{color: "#70b1e7", fontWeight: "bold"}}
       href="https://render.alipay.com/p/yuyan/180020010001196791/preview.html?agreementId=AG00000051"
       target="_blank">《支付宝付款授权服务协议》</a>

  return <div style={{height: "100vh", padding: "0 15px", "overflow": "auto", wordBreak: "break-all"}}>
    <h2 style={{textAlign: "center", marginTop: "20px"}}>知源经络穴位自动续费服务协议</h2>
    <div style={{marginTop: "10px"}}>
      发布日期：2022年 08 月 29 日
    </div>
    <div>
      生效日期：2022年 08 月 29 日
    </div>
    <div>
      更新日期：2025年 03 月 24 日
    </div>
    <div className="text-item">
      感谢您申请使用知源经络穴位为您提供的自动续费委托扣款服务（以下简称为“本服务”），请您仔细阅读并同意《知源经络穴位会员自动续费服务协议》（以下简称为“本协议”）的各项条款为准。如您不同意本协议的全部或部分内容，请勿以任何确认的形式（包括但不限于：勾选、点击）选择“同意并接受”或使用本服务。如您通过勾选“同意并接受知源经络穴位自动续费服务协议”，即表示您与知源经络穴位已达成协议，自愿接受本协议条款的所有内容。此后，您不得以未阅读本协议条款内容作任何形式的抗辩。
    </div>
    <div className="text-item" style={{fontWeight: "bold"}}>
      知源经络穴位会员自动续费服务特别说明：
    </div>
    <div className="text-item" style={{fontWeight: "bold"}}>
      ● 自动续费服务订阅周期：<span style={{color: "#C13737"}}>一个月/一季度/一年（以您实际选择套餐为准）</span>；
    </div>
    <div className="text-item" style={{fontWeight: "bold"}}>
      ● 自动续费服务订阅价格：<span style={{color: "#C13737"}}>以您实际选择的套餐价格为准</span>；
    </div>
    <div className="text-item" style={{fontWeight: "bold"}}>
      ● <span style={{color: "#C13737"}}>开通后在您选择的套餐时限内为您自动续费并自动延长会员</span>；
    </div>
    <div className="text-item" style={{fontWeight: "bold"}}>
      ● 如您希望取消续订，请在扣费前至少24小时关闭自动续订：
    </div>
    <div className="text-item" style={{fontWeight: "bold"}}>
      ● 安卓设备取消方法如下：
    </div>
    <div className="text-item" style={{fontWeight: "bold"}}>
      （1）应用内关闭路径：<span style={{color: "#C13737"}}>会员中心 > 管理订阅 > 取消自动订阅</span>；
    </div>
    <div className="text-item" style={{fontWeight: "bold"}}>
      （2）支付宝关闭路径：<span style={{color: "#C13737"}}>打开支付宝 > 设置 > 自动扣款 > 重庆知源健康管理有限公司 > 关闭服务</span>。
    </div>
    <div className="text-item" style={{fontWeight: "bold"}}>
      ● IOS设备取消方法如下：
    </div>
    <div className="text-item" style={{fontWeight: "bold"}}>
      Apple ID支付方式所开通的自动续费服务，仅可通过下述方式中止或终止本服务：
      <div className="text-item" style={{fontWeight: "bold"}}>
        （1）iOS12及以上系统：<span style={{color: "#C13737"}}>打开苹果iOS设备【App Store】- 点击右上角【苹果账户头像】- 进入【账户】- 点击【订阅】- 选择【知源经络穴位】，取消订阅</span>;
      </div>
      <div className="text-item" style={{fontWeight: "bold"}}>
        （2）iOS12以下系统：<span style={{color: "#C13737"}}>打开苹果手机【设置】- 进入【iTunes Store 与 App Store】- 点击【Apple ID】- 选择【查看Apple ID】- 进入【账户设置】页面 - 点击【订阅】- 选择【知源经络穴位】，取消订阅</span>；
      </div>
    </div>
    <div className="text-item" style={{fontWeight: "bold", marginTop: "30px"}}>
      一、使用本服务的前提条件
    </div>
    <div className="text-item">
      1、本服务是从属于知源经络穴位会员服务的子服务，同时，本服务亦是出于您对自动续费的需求，为避免您因疏忽或其他原因导致未能及时为知源经络穴位在线服务续费造成损失而推出的服务，因此，您已申请开通知源经络穴位在线服务为申请使用本服务的前提。
    </div>
    <div className="text-item">
      2、您在使用本服务时须具备相应的权利能力和行为能力，能够独立承担法律责任，如果您在18周岁以下，必须在父母或监护人的监护参与下才能使用本服务。
    </div>
    <div className="text-item">
      3、本协议作为“知源经络穴位会员服务协议”的子协议，与其具有同等且不可分割之法律效力，因此在使用本服务前，您应接受并同意“知源经络穴位会员服务协”、本协议以及与知源经络穴位会员服务协议、本服务等有关的各类协议、规则、公告的各条款内容，特别是限制或免除责任的相应条款（限制或免除责任条款可能以加粗形式提示您注意）。同时，您的下述行为也将视为您对本协议及知源经络穴位在线服务系列协议所有条款的认可：
    </div>
    <div className="text-item">
      （1）实际使用本服务；
    </div>
    <div className="text-item">
      （2）实际使用知源经络穴位连同本服务一并提供的任何产品或服务（如有）；
    </div>
    <div className="text-item">
      （3）实际使用第三方连同本服务一并提供的任何产品或服务（如有）。
    </div>
    <div className="text-item">
      您对本协议的同意即视为您已知悉并同意本协议知源经络穴位在线服务系列协议的全部条款并且您保证能够完全遵守前述协议。
    </div>
    <div className="text-item" style={{fontWeight: "bold"}}>
      二、本服务项下双方的权利与义务
    </div>
    <div className="text-item">
      1、您申请使用本服务，意味着您不可撤销地授权知源经络穴位可在您会员服务期限届满前后，从您自有的知源经络穴位会员充值账户、与知源经络穴位会员服务账号绑定的支付宝支付（以下统称为“账户”）余额中代扣下一个计费周期的费用，计费周期包括但不限于周度、月度、季度、年度等（下同）（具体以您届时选择本服务时选择的计费周期为准），该服务实现的前提是您已将知源经络穴位在线服务账号与上述账户绑定，且可成功从上述账户中扣款。
    </div>
    <div className="text-item">
      2、自动续费具体是指，基于本条第一款的前提，知源经络穴位通过上述账户收取您下一计费周期的费用，将该款项记入您的支付记录，并同时相应延长您会与服务的有效期。
      <span style={{fontWeight: "bold"}}>您需保证知源经络穴位可以从上述账户扣款成功，因上述账户中可扣款余额不足导致的续费失败，由您自行承担责任。</span>
    </div>
    <div className="text-item" style={{fontWeight: "bold"}}>
      3、您认可并同意：您有义务确保定期关注并确保支付宝的账号项下有充足的余额用于满足本服务的应用。如因前述原因瑕疵（包括但不限于：余额不足、余额不足以满足您选择本服务）而致使本服务无法使用，则知源经络穴位有权在不再作另行通知的前提下，暂停您的会员服务。
    </div>
    <div className="text-item" style={{fontWeight: "bold"}}>
      4、如在扣费过程出现差错，知源经络穴位配合您查明原因，各自承担己方过错造成的损失；若因双方各自存在不均等过错造成的损失，由双方按过错的程度承担相应责任；双方共同负责任的，由双方均摊责任。
    </div>
    <div className="text-item">
      5、知源经络穴位向您提供的本服务均仅限于您在知源经络穴位客户端上使用，
      <span style={{fontWeight: "bold"}}>任何以恶意破解等非法手段将知源经络穴位提供的本服务内容的行为，均不属于本协议中约定的本服务。</span>由此引起的一切法律后果由行为人负责，知源经络穴位将依法追究行为人的法律责任。
    </div>
    <div className="text-item">
      6、本服务由您自主选择是否取消，若您没有选择取消，则视为您同意知源经络穴位按照一定规则进行不定期的扣款尝试，一旦扣款成功，知源经络穴位将为您开通下一个计费周期的自动订阅服务。
    </div>
    <div className="text-item">
      7、若在自动续费时/之前，您通过本服务的服务价格发生调整，应以现时有效的价格为准。
    </div>
    <div className="text-item">
      8、知源经络穴位对您开通本服务不收取任何费用，但知源经络穴位有权根据业务需要或市场变化等原因决定是否对本服务本身进行收费或调整自动续费周期及费用。
    </div>
    <div className="text-item" style={{fontWeight: "bold"}}>
      9.开通自动续费服务后，知源经络穴位将在会员有效期到期日前五日通过短信发送续费通知，并在会员有效期到期日前1日自动在账户扣费并延长该订阅周期对应的会员有效期，即会员授权知源经络穴位在会员开通自动续费的会员服务即将到期时，从会员已开通自动续费的签约支付账户中代扣下一个订阅周期的会员服务费用，并保证本服务实现的前提是可成功从会员开通自动续费服务的账户中扣款。
    </div>
    <div className="text-item" style={{fontWeight: "bold"}}>
      三、本服务的有效期及终止
    </div>
    <div className="text-item">
      1、本服务长期有效，自您选择接受或使用本服务后生效，直至您终止（包括您主动终止及由于您违反相关协议、规则等而被终止）本服务或您通过本服务所接入的具体某项知源经络穴位在线服务时终止。
    </div>
    <div className="text-item">
      2、您有权随时根据本协议第四条的指引选择终止本服务，终止后本服务后，知源经络穴位将停止向您提供本服务。
    </div>
    <div className="text-item" style={{fontWeight: "bold"}}>
      3、您在选择终止本服务前已经委托知源经络穴位自动续费扣款的指令仍然有效，知源经络穴位对于基于该指令扣除的费用不予退还，由您承担相关责任。
    </div>
    <div className="text-item" style={{fontWeight: "bold"}}>
      四、本服务的退订
    </div>
    <div className="text-item">
      1、您可自主选择退订本服务。
    </div>
    <div className="text-item">
      2、如您选择下一计费周期不再继续使用本服务，请您不要晚于本计费周期届满前的24小时内退订本服务，退订流程的步骤如下，具体步骤如有变化，请您届时以本协议文首载明的推定方法为准。
    </div>
    <div className="text-item" style={{fontWeight: "bold"}}>
      五、其他
    </div>
    <div className="text-item">
      1、本协议自动续费服务通过授权支付宝实现，为了进一步改善用户体验，支付宝将会持续更新服务，为用户提供版本升级，功能升级等服务和内容的更新，本协议也可能会随之更新。支付宝会以公告或客户端通知等方式（统称“通知”）就本协议的更新内容向您进行提示，更新内容将在前述通知指定日期开始生效。若您不同意更新后的条款内容，您有权停止使用相关服务（详见：{alipayLink}）。
    </div>
    <div className="text-item">
      2、本协议的成立、生效、履行和解释，均适用中华人民共和国法律；法律无明文规定的，应适用支付宝公司制定的业务规则和通行的商业惯例。本协议为约定部分依照{alipayLink}及相关附属规则予以履行；本协议与{alipayLink}及相关附属规则冲突的，以本协议为准。
    </div>
    <div className="text-item" style={{fontWeight: "bold"}}>
      重庆知源健康管理有限公司
    </div>
  </div>
}
