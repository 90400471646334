import {useSearchParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import JsonRequest from '../../request/json_request';
import "./ClockInvite.css"
import buttonOpenApp from "../../images/invite_code/button-open-app.png"
import {config} from "../../utils/util";
import Tools from "../../utils/tools";
import ClockInviteBg from "../../images/clock-invite/bg.jpg";
import ArrowImg from "../../images/arrow.png";

const wx = global.wx
const jsonRequest = JsonRequest.tokenClient("")

function ClockInvite(props) {
    let [searchParams] = useSearchParams()
    let [openAppSuccess, setOpenAppSuccess] = useState(false)
    const openBtn = useRef(null);
    const extinfo = searchParams.get("extmsg") || "eyJtb2R1bGUiOiAiYWN1cG9pbnQ6Y29pbjpldmVudDo6Y2xvY2staW4ifQ=="

    useEffect(() => {
        jsonRequest.get("/api/wechat/js_config", {url: global.location.href.split('#')[0]})
            .then(resp => resp.json())
            .then(({data}) => {
                wx.config({
                    ...data,
                    // debug: true,
                    jsApiList: [],
                    openTagList: ['wx-open-launch-app']
                });

                const launchFunc = function (e) {
                    Tools.copy(window.location.href)
                    setOpenAppSuccess(true)
                }

                const errFunc = function (e) {
                    if (Tools.isIOS()) {
                        window.location = config.appStoreUrl
                        alert("您还没有安装知源经络穴位，或者版本需要升级")
                    } else if (Tools.isAndroid()) {
                        window.location = config.marketUrl
                        alert("您还没有安装知源经络穴位，或者版本需要升级")
                    }
                    Tools.copy(window.location.href)
                    setOpenAppSuccess(false)
                }

                openBtn.current.addEventListener('launch', launchFunc);
                openBtn.current.addEventListener('error', errFunc);

                document.addEventListener('WeixinOpenTagsError', function (e) {
                    alert(e.detail.errMsg); // 无法使用开放标签的错误原因，需回退兼容。仅无法使用开放标签，JS-SDK其他功能不受影响
                });

                return () => {
                    openBtn.current.removeEventListener('launch', launchFunc);
                    openBtn.current.removeEventListener('error', errFunc);
                };
            })

    }, []);

    const styleStr = `
    .btn.open-app {
        position: fixed;
        bottom: 0;
        width: 100vw;
        height: 93px;
        background: url("${buttonOpenApp}") no-repeat center;
        background-size: contain;
        display: block;
        border: none;
    }`

    return <div className="app-view clock-invite"
                style={{flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <img src={ClockInviteBg} alt=""/>
        {
            Tools.isWechat() ?
                <wx-open-launch-app id="wechat-launch-btn" ref={openBtn} appid="wx96950494c868cde4" extinfo={extinfo}>
                    <script type="text/wxtag-template">
                        <style>
                            {styleStr}
                        </style>
                        <button className="btn open-app"></button>
                    </script>
                </wx-open-launch-app>
                : <a className="btn open-app" ref={openBtn} onClick={Tools.handleH5OpenBtnClick} target="_blank"
                     href={`acupoint3d://`}></a>
        }
        {openAppSuccess != null && !openAppSuccess && <div>

        </div>}
    </div>
}

export default ClockInvite