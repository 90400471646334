import React, {useEffect} from "react";

export default function ServiceAgreement(props) {

  useEffect(() => {
    document.title = "知源经络穴位用户协议"
  }, [])

  return  <div style={{height: "100vh", padding: "0 15px", "overflow": "auto", wordBreak: "break-all"}}>
    <h2 style={{textAlign: "center", marginTop: "20px"}}>知源经络穴位用户协议</h2>
    <div style={{fontWeight: "bold", marginTop: "10px"}}>
      一、总则
    </div>
    <div className="text-item">
      本协议内容包括协议正文、《知源经络穴位用户协议》、《知源经络穴位付费会员服务协议》以及本公司已经发布的或将来可能发布/的各类规则。所有规则均为本协议不可分割的组成部分，与协议正文具有同等法律效力，本协正文与前述规则存在冲突的，以本协议正文为准。除另行明确声明外，您使用自动续费服务均受本协议约束。
    </div>
    <div className="text-item">
      2、知源经络穴位保留随时自行决定修改、变更、添加或删除本服务条款内容的权利，而毋须事先做出通知。如果您在本服务条款修改后访问或使用“知源经络穴位”，则表示您同意接受相关的修改。访问和使用“知源经络穴位”，包括其中的各项特征和内容，受以下条款和条件的约束。在本条款中，“您”或“您的”应包括您自己、您的机构和您在使用“知源经络穴位”时所代表或代理的任何机构。
    </div>
    <div style={{fontWeight: "bold"}}>
      二、使用规则
    </div>
    <div className="text-item">
      1、用户对以其账号发生的或通过其账号发生的一切活动和事件（包括但不限于用户发表的任何内容以及由此产生的任何结果）负全部法律责任。
    </div>
    <div className="text-item">
      2、用户在使用知源经络穴位服务时，必须遵守中华人民共和国相关法律法规的规定，并同意不会利用知源经络穴位服务进行任何违法或不正当的活动，用户应严格遵守以下规则：
    </div>
    <div className="text-item">
      1) 遵守中国法律法规、行政规章以及规范性文件，不得侵犯第三方权利，特别是他人著作权、商标权等知识产权或者合法权利；
    </div>
    <div className="text-item">
      2) 遵守知源经络穴位的所有用户协议、通知、协议等文件，不得为违法、犯罪等目的使用知源经络穴位；
    </div>
     <div className="text-item">
      3) 不得使用任何知源经络穴位内容侮辱或毁谤、骚扰他人，不得以任何方式危害未成年人；
    </div>
     <div className="text-item">
      4) 不得散布淫秽、色情、赌博、暴力、凶杀、恐怖、非法宗教或者教唆犯罪的行为；
    </div>
     <div className="text-item">
      5) 不得利用知源经络穴位从事窃取商业秘密、窃取其他用户个人信息等违法犯罪活动；
    </div>
     <div className="text-item">
      6) 不得侵入本站及知源经络穴位计算机信息系统，不得传播病毒、特洛依木马、定时炸弹等可能对知源经络穴位系统造成伤害或影响其正常运转的恶意病毒或程序；
    </div>
     <div className="text-item">
      7) 不得从事非经知源经络穴位同意的所有牟利性经营活动；
    </div>
     <div className="text-item">
      3、用户为使用知源经络穴位服务，用户须自行配备上网和使用电信增值业务所需的设备，自行负担因上网或第三方（包括但不限于电信或移动通信提供商）收取的通讯费、信息费等有关费用。如涉及电信增值服务的，我们建议用户与其电信增值服务提供商确认相关的费用问题。
    </div>
     <div className="text-item">
      4、用户同意知源经络穴位有权在提供网络服务过程中以各种方式投放各种商业性广告或其他任何类型的商业信息。
    </div>
     <div style={{fontWeight: "bold"}}>
      三、用户账号注册和登录
    </div>
     <div className="text-item">
      1、用户可以通过在知源经络穴位注册账号（微信登录）使用我们提供的各项服务。知源经络穴位账号（包括名字、昵称、用户名等）的所有权归知源经络穴位，用户完成申请注册手续后，获得知源经络穴位账号的使用权，但仅限为个人而非商业目的使用。
    </div>
     <div className="text-item">
      2、用户自行保管其账号。用户账号使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或者售卖。
    </div>
     <div className="text-item">
      3、用户账号遭到他人非法使用或发生其他任何安全问题，用户应当立即通过本协议末联系方式通知我们。因黑客行为或用户的过错导致账号被他人非法使用，知源经络穴位不承担任何责任。
    </div>
     <div className="text-item">
      4、知源经络穴位将建立健全用户信息安全管理制度，按照知源经络穴位隐私保护政策保障用户信息安全。
    </div>
     <div style={{fontWeight: "bold"}}>
      四、知识产权
    </div>
     <div className="text-item">
      1、知源经络穴位服务中包含的任何文字、图表、音频、视频或软件（包括但不限于软件中包含的图表、动画、音频、视频、界面实际、数据和程序、代码、文档）等信息或材料均受著作权法、商标法或其它法律法规的保护，未经相关权利人书面同意，上述资料均不得在任何媒体直接或间接发布、播放、出于播放或发布目的而改写或再发行，或者被用于其他任何商业目的。所有这些资料或资料的任何部分仅可出于中华人民共和国法律允许的用途而保存在某台计算机或其他上网设备内。知源经络穴位不就由上述资料产生或在传送或递交全部或部分上述资料过程中产生的延误、不准确、错误和遗漏或从中产生或由此产生的任何损害赔偿，以任何形式，向用户或任何第三方负责。
    </div>
     <div className="text-item">
      2、本协议未授予用户使用知源经络穴位任何商标、服务标记、标识、域名和其他显著品牌特征的权利。我们或我们的合作第三方如果尚未将“知源经络穴位”相关图形及文字注册为注册商标的，用户不得擅自将其注册成为注册商标；若用户申请注册或已经将其注册为注册商标的，则用户应当配合公司或其合作的第三方向商标主管登记机关申请撤回该注册商标申请或申请撤销该注册商标，或用户应当将该注册商标无偿地、完全地、不可撤销地转让给公司或其合作的第三方。
    </div>
     <div className="text-item">
      3、除本协议明确允许的以外，用户不得以任何形式或任何方式对知源经络穴位服务部分或全部内容进行修改、出租、租赁、出借、出售、分发、复制、创作衍生品或用于任何商业用途。
    </div>
     <div className="text-item">
      4、对于用户通过知源经络穴位和本网络服务（包括但不限于个人信息等）上传到知源经络穴位上可公开获取区域的任何内容，用户同意知源经络穴位在全世界范围内具有免费的、永久性的、不可撤销的、非独家的和完全再许可的权利和许可，以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示此等内容（整体或部分），和/或将此等内容编入当前已知或以后开发的其他任何形式的作品、媒体或技术中。
    </div>
     <div className="text-item">
      5、用户在知源经络穴位上不得侵犯任何第三人的知识产权，未经相关权利人之事先书面同意，用户不得以任何方式上传、发布、修改、传播或复制任何受著作权保护的材料、商标或属于其他人的专有信息。
    </div>
     <div className="text-item">
      6、知源经络穴位对网络知识产权保护尽合理、审慎的义务，在有理由确信用户存在明显侵犯任何第三方知识产权的情形，或者接收到其他第三方关于知识产权的权利主张或投诉时，公司有权不事先通知并随时对该涉嫌侵权的内容采取删除、屏蔽或断开等措施，且无需承担任何责任。若被采取删除、屏蔽或断开等措施的用户认为其行为或内容没有侵犯任何第三方的知识产权，可以向知源经络穴位主张要求恢复其内容或行为，但应当向知源经络穴位提供详细的情况说明和足够的证明文件。我们将根据实际情况判断是否恢复，但即使我们最终决定恢复相关的内容或行为，也仍然不对此前采取的措施承担任何责任。
    </div>
     <div className="text-item">
      7、本软件（包括但不限于软件中所含的任何图象、照片、动画、录像、录音、音乐、文字和附加程序、随附的帮助材料、版面框架、界面设计等）的一切权利均属于知源经络穴位，未经事先书面许可，用户不得对本软件进行反向工程、反向编译或反汇编。
    </div>
     <div className="text-item">
      8、除法律规定外，未经知源经络穴位书面形式的明确许可，任何单位或个人不得以任何方式全部或部分复制、转载、引用、链接、抓取或以其他方式使用知源经络穴位的信息内容。
    </div>
     <div style={{fontWeight: "bold"}}>
      五、隐私政策
    </div>
     <div className="text-item">
      1、保护用户的隐私是知源经络穴位的一项基本政策。
    </div>
     <div className="text-item">
      2、适用于用户的隐私政策参见 《隐私政策》
    </div>
     <div style={{fontWeight: "bold"}}>
      六、免责声明
    </div>
     <div className="text-item">
      1、本协议项下的服务行为涉及到互联网及移动通讯等服务，可能受各个环节不稳定因素的影响，比如不可抗力、计算机病毒或者黑客攻击等造成的服务中断或不能满足用户要求的风险，用户须理解和认可，并承担以上风险。基于上述已经提及的可能发生的各环节不稳定因素影响，知源经络穴位在此声明对服务的及时性、安全性、准确性不作担保，对因此导致用户不能接收信息，或者传递错误等问题不承担任何责任，且不担保本软件或本网络服务一定能满足用户的要求。
    </div>
     <div className="text-item">
      2、如因知源经络穴位系统发生故障而影响到本服务的正常运行，知源经络穴位承诺第一时间内与相关单位配合，及时处理并进行修复。但用户因此而产生的经济损失，知源经络穴位不承担责任。此外，知源经络穴位保留不经事先通知为维修保养、升级或其他目的随时变更、中断或终止部分或全部本服务的权利，而无论同意与否，知源经络穴位对用户和任何第三人均无需承担任何责任。
    </div>
     <div className="text-item">
      3、用户可通过知源经络穴位有关网络服务获得第三方的某些内容，或者知源经络穴位可能为方便用户而提供通往第三方网站的链接，但知源经络穴位不负责检查或评估任何该等第三方材料、产品、服务或网站内容的准确性，并且，知源经络穴位对此不作保证、不承担任何责任、也不负有任何义务。用户对此自行加以判断，并承担因使用该等内容而引起的所有风险，包括但不限于因对内容的正确性、完整性或实用性的依赖而产生的风险。
    </div>
     <div className="text-item">
      4、本软件和/或本网络服务所展现的页面上可能存在其他网页或广告等的链接，知源经络穴位对其他任何网站的内容、隐私政策、运营或经营这些网站的第三方的行为无法控制且概不负责。用户经由知源经络穴位服务与广告商进行通讯联系或商业往来或参与促销活动，完全属于用户与广告商之间的行为，与知源经络穴位没有任何关系，若因商业行为所产生之任何损害或损失，知源经络穴位不承担任何责任。在点击这些网站提供的链接之前，请用户慎重考虑，保护自身的权益及安全。
    </div>
     <div className="text-item">
      5、用户明确同意其使用知源经络穴位服务所存在的风险及其后果将完全由其自己承担，知源经络穴位对用户不承担任何责任。如因用户违反有关法律、法规或本协议项下的任何条款而给知源经络穴位或任何其他第三人造成损失，用户同意承担由此造成的损害赔偿责任。
    </div>
     <div className="text-item">
      6、就下列相关事宜的发生，知源经络穴位在此声明不承担任何法律责任：
    </div>
     <div className="text-item">
      1）用户应遵守国家的有关法律、法规和行政规章制度。如有用户违反国家法律法规或本用户协议，知源经络穴位有权停止向用户提供任务而不需要承担任何责任；如用户的不法使用行为导致知源经络穴位遭受任何损害或者遭受任何来自第三方的纠纷、诉讼、索赔要求等，用户须向知源经络穴位赔偿相应的损失，用户需对其违反用户协议所产生的一切后果承担全部法律责任；
    </div>
     <div className="text-item">
      2）由于您将用户账号告知他人或与他人共享注册账号，由此导致的任何个人信息的泄露，或其他非因知源经络穴位方面原因导致的个人信息的泄露；
    </div>
     <div className="text-item">
      3）根据法律规定或政府相关政策要求提供您的个人信息；
    </div>
     <div className="text-item">
      4）任何通过黑客攻击、电脑病毒侵入等非法截取、访问等方式从我们网站上获取的信息；
    </div>
     <div className="text-item">
      5）以及因台风、地震、海啸、洪水、停电、战争、恐怖袭击等国际通用不可抗力因素导致的任何后果。
    </div>
     <div className="text-item">
      6）因知源经络穴位向用户提供的免费服务本身及其引发的任何损失及或风险。
    </div>
     <div className="text-item">
      7）其他因网络信息或用户行为引起的风险。
    </div>
     <div style={{fontWeight: "bold"}}>
      七、App内购买及退款事宜
    </div>
     <div className="text-item">
      1. 办理会员等付费项之后，如因用户自身原因要求退款的，须联系客服申请并附上具体退款申请说明；
    </div>
     <div className="text-item">
      2. 距办理会员或其他付费项订单完成起至提起退款申请。0-1小时内，视申请而定可作全额退款（需额外收取10%手续费）处理；24小时内，视申请而定可退付费金额50%；如您未在前述约定期限提出申诉，知源经络穴位视为您购买产品/服务无任何问题，超过24小时则不予退款；
    </div>
     <div className="text-item">
      3. 如满足退款条件且申请成功，请耐心等待，退款时间为5-7个工作日；
    </div>
     <div className="text-item">
      4. 订单退款款项统一通过原支付渠道退至会员的支付账户中，届时可查看相关订单及退款说明。
    </div>
     <div style={{fontWeight: "bold"}}>
      八、其他
    </div>
     <div className="text-item">
      1、本协议的版权及本协议下的服务之所有权、运作权和一切解释权归重庆知源健康管理有限公司所有。我们有权在必要时修改并更新用户协议，并通过网站或者移动客户端发布修改变更消息，且不再另行通知。如果在修改更新生效后用户继续使用服务，则视为您接受用户协议的变动并遵守最新用户协议。
    </div>
     <div className="text-item">
      2、本协议的订立、执行和争议的解决均应适用中华人民共和国法律。如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向重庆知源健康管理有限公司所在地有管辖权的人民法院提起诉讼。
    </div>
     <div className="text-item">
      3、重庆知源健康管理有限公司未行使本协议的任何权利或规定，不构成对前述权利之放弃。
    </div>
    <div className="text-item">
      4、如本协议中的任何条款完全或部分无效，本协议的其余条款仍有效并且有约束力。
    </div>
    <div className="text-item">
      5、若您对本协议有任何意见或对本协议的内容存在疑问，欢迎与我们取得联系，或邮寄到如下地址【收件人：重庆知源健康管理有限公司；地址：重庆市渝北区洪湖西路24号B幢11楼080号；邮编：400000】我们将尽快审核所涉问题，并在验证您的用户身份后的三十天内予以回复。
    </div>
    <div className="text-item" style={{fontWeight: "bold"}}>
      重庆知源健康管理有限公司
    </div>
  </div>
}
