import React, {useEffect} from "react";

export default function PrivacyPolicy(props) {

  useEffect(() => {
    document.title = "知源经络穴位隐私政策"
  }, [])

  return <div style={{height: "100vh", padding: "0 15px", "overflow": "auto", wordBreak: "break-all"}}>
    <h2 style={{textAlign: "center", marginTop: "20px"}}>知源经络穴位 隐私政策</h2>
    <div style={{fontSize: "15px", marginTop: "10px"}}>
      版本发布日期：2021年 02 月 18 日
    </div>
    <div>
      版本更新日期：2025年 03 月 24 日
    </div>
    <div>
      版本生效日期：2021年 02 月 18 日
    </div>
    <div
      style={{color: "#000", fontSize: "18px", fontWeight: "bold", marginTop: "16px"}}>前言
    </div>
    <div className="text-item">
      本《知源经络穴位隐私政策》（以下称为“本隐私政策”或“本政策”，本隐私政策同其更新的版本共同构成“本隐私政策”)，适用于用户（又称“您”）所使用的知源经络穴位产品服务提供方（以下称为“知源经络穴位运营方”或“我们”）所提供的产品及服务。本隐私政策将详细说明知源经络穴位运营方在获取、管理及保护您的个人信息方面的政策及措施。
    </div>
    <div className="text-item">
      需要特别说明的是：知源经络穴位产品是指标注名称为知源经络穴位的移动客户端应用程序／QQ小程序/微信小程序及其对应移动网站。
    </div>
    <div className="text-item">
      在您使用知源经络穴位运营方提供的服务前，请务必仔细阅读并理解本隐私政策（尤其是其中加粗的条款），详细了解我们对信息的收集、使用方式，并自主选择是否同意相关内容。选择“同意”或进行“下一步”（或进行其他具有不同称呼但性质相同的同类操作）则表示您已充分理解并同意签署本协议，承诺作为协议的一方当事人接受协议的约束，同意知源经络穴位运营方按照本隐私政策的约定处理您的个人信息。如果您对本协议的任何条款有异议，或者无法准确理解本协议任何条款，请不要访问和／或使用知源经络穴位应用及其相关服务。
    </div>
    <div className="text-item">
      未满十八周岁的未成年人应当在监护人陪同下阅读本政策，并在取得他们对您使用服务的行为以及对本协议全部条款的同意之后，方可使用知源经络穴位的服务。
    </div>
    <div className="text-item">
      如果您对本隐私政策有任何意见、建议或疑问，您可以通过本隐私政策公示的方式（可以将相关内容发送至：zj@nineton.cn）或知源经络穴位内提供的其他联系／反馈渠道联系我们，我们将尽快为您解答。
    </div>
    <div className="text-item">
      其中，<span className="highlight">与您信息及权利相关的内容将以加粗的形式提示您注意，请您重点阅读。</span>
    </div>
    <div style={{
      color: "#000",
      fontSize: "18px",
      fontWeight: "bold",
      marginTop: "16px"
    }}>第一部分 定义
    </div>
    <div className="text-item">
      个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇的个人信息。本隐私政策主要涉及的敏感信息主要包括：
      <span className="highlight">网络身份识别信息（包括账户名、账户昵称、邮箱地址及同前述有关的密码保护问题与对应保护问题的答案）；其他信息（包括个人电话号码、手机号码、位置信息（定位信息））。</span>
    </div>
    <div style={{color: "#000", fontSize: "18px", fontWeight: "bold", marginTop: "16px"}}>
      第二部分 个人信息隐私政策
    </div>
    <div style={{marginTop: "16px"}}>
      本政策将帮助您了解以下内容：
    </div>
    <div style={{marginTop: "16px"}}>
      1．我们如何收集和使用您的个人信息
    </div>
    <div style={{marginTop: "16px"}}>
      2．对 COOKIES 和同类技术的使用及管理
    </div>
    <div style={{marginTop: "16px"}}>
      3．我们如何共享、转让、公开披露您的个人信息
    </div>
    <div style={{marginTop: "16px"}}>
      4．我们如何保护及保存您的个人信息
    </div>
    <div style={{marginTop: "16px"}}>
      5．您如何访问及控制您的个人信息
    </div>
    <div style={{marginTop: "16px"}}>
      6．对未成人个人信息的特别保护
    </div>
    <div style={{marginTop: "16px"}}>
      7．您的个人信息如何在全球范围转移
    </div>
    <div style={{marginTop: "16px"}}>
      8．应用权限申请与使用说明
    </div>
    <div style={{marginTop: "16px"}}>
      <a style={{color: "#70b1e7"}}
         href="https://sdk-ref.lighttcm.com/h5/sdk?key=pSEbtCbz5eU0%2F2Qy4D3S1QHv9mKKDlugRA0LFxm2D%2Fo%3D"
         target="_blank">9．第三方信息共享服务商清单</a>
    </div>
    <div style={{marginTop: "16px"}}>
      10．本政策如何更新
    </div>
    <div style={{marginTop: "16px"}}>
      11．如何联系我们
    </div>
    <div style={{marginTop: "16px", fontSize: "18px", fontWeight: "bold", color: "#000"}}>
      一、我们如何收集和使用您的个人信息
    </div>
    <div style={{marginTop: "16px", fontSize: "16px", fontWeight: "bold", color: "#333"}}>
      (一)、我们收集的信息
    </div>
    <div style={{marginTop: "16px"}}>
      1．根据合法、正当、必要的原则，收集实现产品功能所必要的信息：
    </div>
    <div className="text-item">
      <div>(1）您在注册账户时所填写的信息</div>
      <div>为了完成账号的创建以便我们为您提供服务，您在注册知源经络穴位账户时所填写的昵称、手机号码；您也可以使用第三方账号（如微信）登录知源经络穴位，此时您可以选择提供第三方账号相关昵称、头像信息。</div>
    </div>
    <div className="text-item">
      <div>(2）您在使用服务时上传的信息</div>
      <div>您在使用知源经络穴位时所上传的头像、分享的照片。</div>
    </div>
    <div className="text-item">
      <div>(3）您在通过我们提供的联系／反馈渠道或参加我们举办的活动时所提交的信息</div>
      <div>您在参与我们线上活动时填写的调查问卷可能包含有您的姓名、电话、家庭地址信息。</div>
    </div>
    <div className="text-item">
      <div>
        (4）我们的部分服务可能需要您提供特定的个人敏感信息来实现特定功能。若您选择不提供该类信息，则可能无法正常使用服务中的特定功能，但不影响您使用服务中的其他功能。若您主动提供您的个人敏感信息，即表示您同意我们按本政策所述目的和方式来处理您的个人敏感信息。
      </div>
      <div>您于个人资料信息中可以自行选择是否展示您的性别、年龄，如已选择展示，您可以随时对该展示进行更改或关闭，以上更改或关闭个人性别、年龄展示不影响您对其他功能的使用。</div>
    </div>
    <div style={{marginTop: "16px"}}>
      2．我们在您使用服务时获取的信息
    </div>
    <div className="text-item highlight">
      <div>在您使用服务时，我们会接收并记录设备上的信息，包括但不限于您的服务日志信息、IP地址、位置信息（定位信息）、访问日期和时间、软硬件特征信息（设备序列号、ANDROID ID、IMEI、MAC地址、SIM卡序列号、IMSI识别码、OAID、SSID、BSSID、MEID、ICCID、SD卡数据、已安装应用列表（已安装应用信息/应用列表）、剪切板（剪贴板）信息），用于鉴别用户身份、记录系统故障和分析系统故障时产生的原因、用户行为分析、开展内部审计、数据分析和研究，以促进改善、优化我们的产品、服务。</div>
    </div>
    <div className="text-item highlight">
      <div>(1）网络日志及设备信息</div>
      <div>当您使用我们的服务时，我们可能会自动收集以下相关信息并存储</div>
      <div>· 客户端硬件特征</div>
      <div>设备型号、设备硬件序列号（Serial Number）、操作系统版本、电池、信号强度</div>
      <div>· 软件特征信息</div>
      <div>软件的版本号、浏览器类型。为确保操作环境的安全或提供服务所需，我们会收集有关您使用的移动应用和其他软件的信息。</div>
      <div>· 服务日志信息</div>
      <div>您在使用我们服务时搜索、查看的信息、服务故障信息。</div>
      <div>· 账户信息</div>
      <div>
        如您使用的是客户端，为了帮助您更好地使用我们的产品或服务，如您选择开启导入通讯录功能，我们可能对您联系人的姓名和电话进行加密收集。其中，好友列表部分（如有）仅用于建立和管理用户在即时通讯应用的联系人关系，您可以自行手动添加好友。群列表部分（如有）仅用于实现群组聊天功能。
      </div>
      <div>
        您在使用我们的小程序服务时，该服务为基于腾讯的功能，您需要使用QQ账号登录知源经络穴位小程序并创建对应账号后方可开始使用，除经过您授权后基于创建知源经络穴位账号需要的头像信息、账户信息外，知源经络穴位运营方不会收集其他非必要信息。同时，您可以自行选择是否允许当前小程序内的用户添加您为好友，如您选择允许，则后期您也可于应用内对该权限进行管理。
      </div>
      <div>· 其他日志及设备信息</div>
      <div>除以上信息外，我们可能收集到的其他日志及设备相关信息主要包括：相机（摄像头）、相册、IMEI、MAC、SIM卡序列号IMSI识别码、设备设置、进程及软件列表、唯一设备标识符。</div>
    </div>
    <div className="text-item">
      <div>(2）位置信息（定位信息）</div>
      <div className="highlight">
        仅在当您使用与位置信息（定位信息）有关的服务时，我们可能会记录您设备所在的位置信息（定位信息），以便为您提供相关服务。在您使用服务时，我们可能会通过IP地址、GPS、WLAN或基站获取您的位置信息（定位信息）；您或其他用户在使用服务时提供的信息中可能包含您所在位置信息（定位信息）；您提供的账号信息中可能包含的您所在地区信息，您共享的地理标记信息。
      </div>
    </div>
    <div className="text-item">
      <div>(3）望诊有关的服务涉及的信息</div>
      <div className="highlight">
        在当您使用与望诊助手有关的服务时，我们可能会申请相册、相机（摄像头）权限，并记录您在使用服务时的产生的影像和语音内容以及相册内容。同时为达到提高诊断相关的准确性和服务质量及向您提供更适合的相关内容或功能的目的，我们将允许相关工作人员查看记录的影像和语音内容。
      </div>
    </div>
    <div className="text-item">
      <div>(4）支付功能的使用涉及的信息</div>
      <div className="highlight">您可以在知源经络穴位进行充值用以兑换礼物道具或兑换其他增值服务。在您支付过程中，我们可能会收集您的第三方支付账号：QQ钱包账号、微信支付账号、apple pay
        账号或其他形式的银行卡信息及实名认证信息。如果您不同意我们收集这些信息，我们将不会为您提供相关服务，但不影响您使用产品的其他基本功能。
      </div>
    </div>
    <div className="text-item">
      <div>(5）剪切板（剪贴板）信息</div>
      <div className="highlight">在你分享或接收被分享的信息、参加活动的情形下，我们需要访问你的剪切板（剪贴板），读取其中包含的口令、分享码、链接，以实现获取活动信息功能。</div>
      <div
        className="highlight">【特别说明】有些手机或其他终端设备的系统会提示应用（App）正在读取剪切板（剪贴板），但该系统提示无法区分是否收集剪切板（剪贴板）信息，且不排除误报。因此，如果你发现类似提示，请与我们联系，以便我们定位问题。
      </div>
    </div>
    <div className="text-item">
      <div>(6）其他相关信息</div>
      <div className="highlight">
        为了帮助您更好地使用我们的产品或服务，我们会收集除以上列明的信外的相关信息：我们可能会为改善产品、进行商业化采集相关信息用于研究、统计分析和预测（包括使用匿名数进行机器学习、进行模型算法训练）。同时，当您与我们联系时，为了验证您的身份，我们可能会记录您与我们的对话并收集其他为解决问题所需的必要信息。
      </div>
    </div>
    <div className="text-item highlight">
      此外，我们将在App静默/后台状态中收集您设备上的信息，包括但不限于您的服务日志信息、IP地址、访问日期和时间、软硬件特征信息（IMEI、MAC、SIM卡序列号、IMSI识别码）、唯一设备标识符，用于鉴别记录系统故障和分析系统故障时产生的原因、用户行为分析、开展内部审计、数据分析和研究，以促进改善、优化我们的产品、服务。
    </div>
    <div className="text-item highlight">
      无论如何，我们在对以上信息做收集时仍会依照“最小的限度”以及“必要”的原则进行，同时将依照规定做好相关数据的保护工作。
    </div>
    <div style={{marginTop: "16px"}}>
      3．其他用户分享的信息中含有您的信息
    </div>
    <div className="text-item">
      其他用户发布的照片或分享的视频中可能包含您的信息。
    </div>
    <div style={{marginTop: "16px"}}>
      4．征得同意的例外
    </div>
    <div className="text-item highlight">
      <div>请充分知悉，在以下情形中，我们收集、使用个人信息无须（再次）征得您的授权同意：</div>
      <div>(1）与国家安全、国防安全有关的；</div>
      <div>(2）与公共安全、公共卫生、重大公共利益有关的；</div>
      <div>(3）与犯罪侦查、起诉、审判和判决执行有关的；</div>
      <div>(4）出于维护个人信息主体或其他个人的重大合法权益（生命、财产）但又很难得到本人同意的；</div>
      <div>(5）所收集的个人信息是个人信息主体自行向社会公众公开的；</div>
      <div>(6）从合法公开披露的信息中收集的您的个人信息的；</div>
      <div>(7）根据您的要求签订和履行合同所必需的；</div>
      <div>(8）用于维护所提供的服务的安全稳定运行所必需的；</div>
      <div>(9）法律法规规定的其他情形。</div>
    </div>
    <div style={{marginTop: "16px", fontSize: "16px", fontWeight: "bold", color: "#333"}}>
      (二)、我们如何使用收集的信息
    </div>
    <div style={{marginTop: "16px"}} className="highlight">
      1．我们严格遵守法律法规的规定及与用户的约定，将收集的信息用于以下用途，若我们超出以下用途使用您的信息，我们将再次向您进行说明，并征得您的同意：
    </div>
    <div className="text-item">
      <div>(1）向您提供服务</div>
      <div>(2）产品开发和服务优化</div>
      <div>当我们的系统发生故障时，我们会记录和分析系统故障时产生的信息，优化我们的服务。</div>
      <div>(3）个性化推送内容</div>
      <div>为向您提供更多免费、优质的服务，尽量减少与您无关的内容对您造成的侵扰，我们会收集【设备信息、订单信息、大致位置信息（定位信息）、会员身份信息】信息，用以向您进行个性化推荐、展示或推送您感兴趣的特定内容信息，或者推送更合适您的特定功能或服务，以便能更好地满足您的需求。请您了解，我们收集、使用上述信息时进行了去标识化处理，数据分析仅对应特定的、无法直接关联用户身份的编码，无法也绝不会与您的真实身份相关联。如您不接受此个性化推荐，您可以通过【我的】-【设置】-【个性化推荐】选择更改。</div>
      <div>(4）安全保障</div>
      <div>我们会将您的信息用于身份验证、安全防范、反诈骗监测、存档备份、客户的安全服务。您下载或安装的安全软件会对恶意程序或病毒进行检测，或为您识别诈骗信息。</div>
      <div>该情形下您可以选择是否接收我们的推送资讯。如您希望关闭推送，您可以在知源经络穴位应用中进行关闭，您也可以选择在手机设置中进行关闭。</div>
    </div>
    <div style={{marginTop: "16px"}} className="highlight">
      2．为了让您有更好的体验、改善我们的服务或经您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某些服务所收集的信息用于我们的其他服务：将您在使用我们某项服务时的信息，用于用户研究分析与统计。
    </div>
    <div style={{marginTop: "16px"}} className="highlight">
      3．为了确保服务的安全，帮助我们更好地了解我们应用程序的运行情况，我们可能记录相关系信息：您使用应用程序的频率、故障信息、总体使用情况、性能数据以及应用程序的来源。我们不会将为我们存储在分析软件中的信息与您在应用程序中提供的个人身份信息相结合。
    </div>
    <div style={{marginTop: "16px", fontSize: "18px", fontWeight: "bold", color: "#000"}}>
      二、对COOKIES和同类技术的使用及管理
    </div>
    <div style={{marginTop: "16px", fontSize: "16px", fontWeight: "bold", color: "#333"}}>
      (一)、COOKIES
    </div>
    <div className="text-item">
      COOKIES是一种可让网站服务器将数据存储于客户端或从客户端中读取数据的中立技术。COOKIES文件是存储了一些与用户访问网站有关信息的文件。COOKIES内容通常均经过加密，安全的COOKIES技术会为用户在上网时提供更为便捷的服务。
    </div>
    <div style={{marginTop: "16px", fontSize: "16px", fontWeight: "bold", color: "#333"}}>
      (二)、COOKIES的使用
    </div>
    <div className="text-item">
      为确保网站正常运转，我们或我们的第三方合作伙伴，可能会在您的计算机或移动设备上放置安全的COOKIES及相关技术收集您的信息，目的是为您提供更好的用户体验和服务。COOKIES通常包含标识符、站点名称以及一些号码和字符。我们会严格要求第三方合作伙伴遵守本政策的相关规定。
    </div>
    <div className="text-item">
      使用此类技术可以帮助您省去重复填写个人信息、输入搜索内容的步骤和流程，通过该技术我们可以向您提供“一键登录”功能、帮助您记录搜索历史。
    </div>
    <div className="text-item">
      我们不会将COOKIES用于本政策所述目的之外的任何用途。您可以通过浏览器设置管理COOKIES，包括是否启用以及选择进行关闭。但如果停用COOKIES，您可能无法使用某些服务。
    </div>
    <div className="text-item">
      如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。
    </div>
    <div style={{marginTop: "16px", fontSize: "16px", fontWeight: "bold", color: "#333"}}>
      (三)、Do Not Track（请勿追踪）
    </div>
    <div className="text-item">
      部分网络浏览器设有Do Not Track功能，该功能可向网站发送Do Not Track请求。如果您的浏览器启用了Do Not Track，我们将尊重您的选择。
    </div>
    <div style={{marginTop: "16px", fontSize: "18px", fontWeight: "bold", color: "#000"}}>
      三、我们如何共享/转让/公开披露您的个人信息
    </div>
    <div style={{marginTop: "16px"}}>
      在为相关共享、转让和公开披露行为时，我们只会共享、转让和公开披露必要的个人信息，且受本政策中所声明目的约束。
    </div>
    <div style={{marginTop: "16px", fontSize: "16px", fontWeight: "bold", color: "#333"}}>
      (一)、个人信息的使用和提供
    </div>
    <div className="text-item highlight">
      未经您同意，知源经络穴位运营方不会向其自身以外的任何公司、组织和个人共享您的个人信息，但以下情形除外：
    </div>
    <div className="text-item highlight">
      1．已获得明确授权同意的共享：已经取得您或您的监护人的授权或同意。如应您的需求为您处理您与他人的纠纷或争议或符合与您签署的相关协议（包括知源经络穴位软件许可及服务协议）或其他的法律文件约定所提供；
    </div>
    <div className="text-item highlight">
      2．法定情形下的共享：根据相关法律法规规定，为诉讼活动的需要，或为满足司法机关或行政机关基于法定程序要求披露。在法律法规允许的范围内，为维护知源经络穴位或知源经络穴位的合作伙伴、您、其他知源经络穴位用户或社会公众的利益、财产、安全免遭损害而有必要提供的；
    </div>
    <div className="text-item highlight">
      3．与知源经络穴位运营方的关联公司共享：为了向您提供服务，为保护您或者知源经络穴位运营方的关联公司或其他用户的人身财产安全免受侵害，知源经络穴位运营方可能会与其关联公司共享您的个人信息；
    </div>
    <div className="text-item highlight">
      4．与知源经络穴位运营方授权合作伙伴的共享：我们会委托授权合作伙伴为您提供某些服务或代表我们履行某些职能。为保障您的个人信息安全，我们在与合作伙伴共享您的个人信息前，会与其签署相应的个人信息使用或保密协议，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理个人信息。
    </div>
    <div style={{marginTop: "16px"}}>
      目前，我们的授权合作伙伴包括以下类型：
    </div>
    <div className="text-item">
      (1）分析服务类的授权合作伙伴。除非得到您的许可或者依照规定对数据做去标识化、匿名化处理，否则我们不会将您的个人身份信息与提供分析服务的合作伙伴共享。我们可能会向这些合作伙伴提供有关其规划报告覆盖面和有效性的信息，但不会提供您的个人身份信息，或我们将这些信息进行经匿名化处理后汇总，以确保它不会识别您个人。这类合作伙伴可能将上述信息与他们合法获取的其他数据进行结合，以进行决策建议。
    </div>
    <div className="text-item">
      (2）供应商、服务提供商和其他合作伙伴。基于业务需要，我们可能会将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。特别的，在涉及存储服务时，我们将对依照规定进行存储服务的内容采取严格的保密措施，如涉及租用第三方存储空间的，我们将严格要求该第三方在最小范围内对数据进行接触并且严格保密。
    </div>
    <div className="text-item highlight">
      提示：您可以向特定的对象发送照片、身份信息、位置信息（定位信息），也可以基于知源经络穴位的服务向不特定人共享自己的照片、位置信息（定位信息）、身份信息。与此同时，您应当自行对相关对象进行甄别或采取适当措施自我保护，若您因为您的共享行为导致您的信息发生泄漏，知源经络穴位运营方不承担相关法律责任。
    </div>
    <div style={{marginTop: "16px", fontSize: "16px", fontWeight: "bold", color: "#333"}}>
      (二)、转让
    </div>
    <div className="text-item">
      我们不会将您的个人信息转让给任何公司、组织、个人，但以下情形除外：
    </div>
    <div className="text-item">
      1．在获取您的明确同意的情形下；
    </div>
    <div className="text-item">
      2．在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织重新向您征求授权同意；
    </div>
    <div className="text-item">
      3．符合与您签署的相关协议（包括在线签署的电子协议）或其他的法律文件约定所提供；
    </div>
    <div className="text-item">
      4．根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供。
    </div>
    <div style={{marginTop: "16px", fontSize: "16px", fontWeight: "bold", color: "#333"}}>
      (三)、公开披露
    </div>
    <div className="text-item">
      我们仅会在以下情况下，公开披露您的个人信息：
    </div>
    <div className="text-item">
      1．获得您明确同意或基于您的主动选择；
    </div>
    <div className="text-item">
      2．基于法律法规规定的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
    </div>
    <div style={{marginTop: "16px", fontSize: "16px", fontWeight: "bold", color: "#333"}}>
      (四)、无需事先征得授权同意的共享、转让以及公开披露
    </div>
    <div className="text-ite highlight">
      基于相关法律法规的规定，以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
    </div>
    <div className="text-item highlight">
      1．与国家安全、国防安全有关的；
    </div>
    <div className="text-item highlight">
      2．与公共安全、公共卫生、重大公共利益有关的；
    </div>
    <div className="text-item highlight">
      3．与犯罪侦查、起诉、审判和判决执行有关的；
    </div>
    <div className="text-item highlight">
      4．出于维护您或其他个人的重大合法权益（生命、财产）但又很难得到本人同意的；
    </div>
    <div className="text-item highlight">
      5．您自行向社会公众公开的个人信息；
    </div>
    <div className="text-item highlight">
      6．从合法公开披露的信息中收集个人信息的。
    </div>
    <div className="text-item highlight">
      根据法律法规规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
    </div>

    <div style={{marginTop: "16px", fontSize: "18px", fontWeight: "bold", color: "#000"}}>
      四、我们如何保护及保存您的个人信息
    </div>
    <div style={{marginTop: "16px", fontSize: "16px", fontWeight: "bold", color: "#333"}}>
      (一)、个人信息的保护
    </div>
    <div className="text-item">
      1．知源经络穴位运营方将依照相关规定对知源经络穴位进行等级测评与安全评估。
    </div>
    <div className="text-item">
      2．我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们在中华人民共和国境内收集和产生的个人信息存储于中华人民共和国境内。
    </div>
    <div className="text-item">
      3．知源经络穴位运营方内有严格的数据保护及管理措施，我们会采取安全保障措施努力保护您的个人信息不被未经授权地访问、使用、披露、修改、损坏或丢失及其他形式的非法处理。
    </div>
    <div className="text-item">
      4.若不幸发生知源经络穴位运营方所获取并存储的数据安全受到危害的情形，或由于外部行为（黑客攻击）使用户的非公开信息被披露给不相关第三方情形，知源经络穴位运营方将启动应急预案，组成紧急应急小组，启动流程，进行内部调查，上报并通知监管部门、以及配合监管部门工作，降低事故对用户的影响，同时会采取推送通知、邮件、公告的形式告知相关用户。
    </div>
    <div className="text-item">
      5．若我们的产品或服务发生停止运营情形时，我们将及时停止继续收集您的个人信息的活动并通过推送通知、发布公告的形式告知您停止运营的情形。无论如何，我们都会在终止服务或运营后对我们所持有的您的个人信息进行删除或匿名化处理。
    </div>
    <div style={{marginTop: "16px", fontSize: "16px", fontWeight: "bold", color: "#333"}}>
      (二)、个人信息的存储
    </div>
    <div className="text-item">
      知源经络穴位运营方会采取合适的安全措施和技术手段存储及保护您的个人信息，以防止丢失、被误用、受到未授权访问或泄漏、被篡改或毁坏。我们仅在实现本隐私政策所属目的必须的期限依照个人信息的不同等级对应的存储期限对个人信息进行存储，存储期限严格按照法律及相关法规规定。根据本条款的规定，我们仅允许有必要知晓这些信息的员工或第三方访问个人信息，并要求他们履行相应的保密义务。
    </div>

    <div style={{marginTop: "16px", fontSize: "18px", fontWeight: "bold", color: "#000"}}>
      五、您如何访问及控制您的个人信息
    </div>
    <div style={{marginTop: "16px"}}>
      您可以通过相关途径访问权、更新/更正、删除您的个人信息或改变您授权同意的范围。
    </div>
    <div className="text-item">
      1．查阅、修改个人信息
    </div>
    <div className="text-item">
      当您完成账号注册并进行合理和必要的身份验证后，您可以查阅、修改、删除您所提交的个人信息。一般情况下，您可以随时浏览、修改自己提交的信息，但出于安全性和身份识别（如号码申诉服务）的考虑，您可能无法修改注册时提供的某些初始注册信息及验证信息。
    </div>
    <div className="text-item">
      2．更新、更正个人信息
    </div>
    <div className="text-item">
      您有权自主或授权知源经络穴位运营方对您的个人信息进行更新、更正，基于个人信息保护的需要，在对某些重要信息进行更新/更正前，我们能会要求您进行身份验证。
    </div>
    <div className="text-item">
      3．删除个人信息
    </div>
    <div className="text-item">
      （1）您可以自行选择如何删除您在注册账户或进行动态发布时提交的个人信息：
    </div>
    <div className="text-item highlight">
      · 通过点击【我的】进入个人页面，点击【设置】—【账户与安全】—【注销账号】进行注销，立即生效；
    </div>
    <div className="text-item highlight">
      · 通过发送电子邮件至zj@nineton.cn申请注销，我们核实后将在15个工作日内为您注销账号；
    </div>
    <div className="text-item">
      （2）在以下情形，您可以向我们提出删除个人信息的请求：
    </div>
    <div className="text-item">
      · 我们存在未征得您的明确同意即收集、使用您的个人信息的行为
    </div>
    <div className="text-item">
      · 我们处理个人信息的行为严重违反了与您的约定
    </div>
    <div className="text-item">
      · 我们处理个人信息的行为违反了法律法规的规定
    </div>
    <div className="text-item">
      · 您主动注销或关闭了您的账号
    </div>
    <div className="text-item">
      当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并将其进一步隔离，使其在我们提供的产品内保持不被检索、访问的状态，直到备份可以清除或实现匿名。
    </div>
    <div className="text-item">
      4．撤回或改变授权
    </div>
    <div className="text-item">
      您可以通过设备自身的权限设置，对允许知源经络穴位客户端访问的设备权限进行设置和修改，相关设置和修改生效后，知源经络穴位运营方将无法向您提供对应服务。您撤回或改变您的授权，不会影响此前基于您的授权而进行的个人信息收集、使用行为。
    </div>
    <div className="text-item">
      5．注销／管理账号
    </div>
    <div className="text-item">
      如您使用的是客户端（如有），则您可以通过客户端相应流程／途径注销知源经络穴位账号。客户端注销具体途径如下:通过点击我的—设置—账户与安全—注销账户。如果您是通过发送电子邮件或在线客服的方式申请注销账户的，我们核查后会在15日内为您注销账号。您注销账号的行为是不可逆的，您的账号被注销后，知源经络穴位运营方将不会在平台界面内展示您的个人信息及已删除的信息，同时，您也无法通过已注销的账户获得知源经络穴位的相关服务。对于您已注销的账号及账号相关联信息，我们会依据适用法律法规的要求将信息保存至法定期限届满后尽快删除或采用去标识化处理。
    </div>
    <div className="text-item">
      如您使用的是知源经络穴位的小程序服务，则基于账号创建的特性（基于腾讯QQ账号进行创建）您可以选择关闭账号，当前关闭账号的具体途径如下：设置-关闭用户信息。您选择关闭账号后，知源经络穴位运营方将不会在平台界面内展示您的个人信息及已删除的信息，同时，您也无法通过已关闭的账户获得知源经络穴位的相关服务。对于您已关闭的账号及账号相关联信息，我们会依据适用法律法规的要求将信息保存至法定期限届满后尽快删除或采用去标识化处理。知源经络穴位账号的关闭不会影响您正常使用
      QQ 账号。
    </div>

    <div style={{marginTop: "16px", fontSize: "18px", fontWeight: "bold", color: "#000"}}>
      六、对未成人个人信息的特别保护
    </div>
    <div className="text-item">
      未成年人使用我们的产品或服务应当取得监护人的同意。若您是未满十八周岁的未成年人，您在使用我们的产品或服务前应请您的父母或监护人仔细阅读本隐私政策，并在取得您的父母或监护人的同意的前提下使用我们的产品或服务或向我们提供信息。知源经络穴位运营方在依据法律规定或本协议约定要求您承担责任时，有权向您的监护人追偿。如您是未成年人的父母或监护人，您在同意给监护人使用我们的产品或服务前，应详细阅读本协议的所有内容。
    </div>
    <div className="text-item">
      提示：如您是未成年人的父母或监护人，您在同意给监护人使用我们的产品或服务前，应详细阅读本隐私政策的所有内容，如有任何疑问，可以通过本隐私政策中公示的联系方式与我们联系。
    </div>
    <div className="text-item">
      您在知源经络穴位上申请注册账号，我们将默认为您已得到前述同意。我们将根据国家相关法律法规及本隐私政策的规定保护未成年人的个人信息。
    </div>

    <div style={{marginTop: "16px", fontSize: "18px", fontWeight: "bold", color: "#000"}}>
      七、您的个人信息如何在全球范围转移
    </div>
    <div className="text-item">
      知源经络穴位运营方将在中华人民共和国境内运营过程中收集和产生的个人信息存储在中国境内，个人信息存储时间为三年，以下情形除外：
    </div>
    <div className="text-item">
      1．使用的法律有明确规定的；
    </div>
    <div className="text-item">
      2．获得您的明确授权的；
    </div>
    <div className="text-item">
      3．您通过互联网进行的个人主动行为。
    </div>
    <div className="text-item">
      针对以上情形，知源经络穴位运营方会确保根据本隐私政策对您的个人信息提供足够的保护。
    </div>
    <div style={{marginTop: "16px", fontSize: "18px", fontWeight: "bold", color: "#000"}}>
      八、应用权限申请与使用说明
    </div>
    <div className="text-item">
      为保障您的知情权，我们通过以下列表将知源经络穴位可能申请、使用的相关操作系统权限进行展示，为了保护您的隐私，请您仔细阅读并了解我们对于设备权限的调取情况，您可以根据实际需要对相关权限进行管理。
    </div>
    <div style={{marginTop: "16px"}} className="text-item highlight">
      知源经络穴位Android应用权限列表：
    </div>
    <div className="text-item">
      1．权限名称：android.permission.INTERNET android.permission.ACCESS_NETWORK_STATE android.permission.ACCESS_WIFI_STATE
    </div>
    <div className="text-item">
      权限描述：网络权限
    </div>
    <div className="text-item">
    </div>
    <div className="text-item">
      使用场景和目的：访问网络
    </div>
    <div className="text-item">
      2．权限名称：android.permission.VIBRATE
    </div>
    <div className="text-item">
      权限描述：震动权限
    </div>
    <div className="text-item">
      使用场景和目的：客服消息提醒
    </div>
    <div className="text-item">
      3．权限名称：android.permission.WAKE_LOCK
    </div>
    <div className="text-item">
      权限描述：保持屏幕唤醒
    </div>
    <div className="text-item">
      使用场景和目的：视频播放页面保持屏幕唤醒
    </div>
    <div className="text-item">
      4．权限名称：android.permission.FOREGROUND_SERVICE
    </div>
    <div className="text-item">
      权限描述：应用程序前台服务
    </div>
    <div className="text-item">
      使用场景和目的：音频播放通知栏显示
    </div>
    <div className="text-item">
      5．权限名称：android.permission.CAMERA
    </div>
    <div className="text-item">
      权限描述：相机(摄像头)权限
    </div>
    <div className="text-item">
      使用场景和目的：望诊助手拍照、客服沟通时拍照上传
    </div>
    <div className="text-item">
      6．权限名称：android.permission.REQUEST_INSTALL_PACKAGES
    </div>
    <div className="text-item">
      权限描述：安装包下载权限
    </div>
    <div className="text-item">
      使用场景和目的：App内更新
    </div>
    <div className="text-item">
      7．权限名称：android.permission.READ_PHONE_STATE
    </div>
    <div className="text-item">
      权限描述：电话（设备信息）权限
    </div>
    <div className="text-item">
      使用场景和目的：获取设备信息（如IMEI、序列号Serial Number），用于网络、消息推送、记录行为日志、数据分析
    </div>
    <div className="text-item">
      8．权限名称：android.permission.READ_EXTERNAL_STORAGE android.permission.WRITE_EXTERNAL_STORAGE
    </div>
    <div className="text-item">
      权限描述：读取、写入、删除存储空间（媒体与文件/文件和文档/音乐与音频文件/照片和视频/相册）
    </div>
    <div className="text-item">
      使用场景和目的：截图保存、在线客服上传图片、望诊助手读取相册
    </div>
    <div className="text-item">
      9．权限名称：android.permission.POST_NOTIFICATIONS
    </div>
    <div className="text-item">
      权限描述：通知权限
    </div>
    <div className="text-item">
      使用场景和目的：向用户推送信息、平台通知、更新推送
    </div>
    <div className="text-item">
      10．权限名称：android.permission.ACCESS_FINE_LOCATION android.permission.ACCESS_COARSE_LOCATION android.permission.ACCESS_BACKGROUND_LOCATION
    </div>
    <div className="text-item">
      权限描述：位置权限
    </div>
    <div className="text-item">
      使用场景和目的：基于当前位置计算真太阳时
    </div>
    <div className="text-item">
      11．权限名称：android.permission.RECORD_AUDIO android.permission.MODIFY_AUDIO_SETTINGS
    </div>
    <div className="text-item">
      权限描述：录音权限
    </div>
    <div className="text-item">
      使用场景和目的：用于语音输入识别文字
    </div>
    <div style={{marginTop: "16px"}} className="text-item highlight">
      知源经络穴位IOS应用权限列表：
    </div>
    <div className="text-item">
      1．权限名称：Privacy - Photo Library Usage Description(NSPhotoLibraryUsageDescription)、Privacy - Photo Library Additions
      Usage Description(NSPhotoLibraryAddUsageDescription)
    </div>
    <div className="text-item">
      权限描述：相册访问权限
    </div>
    <div className="text-item">
      使用场景和目的：截图保存、在线客服上传图片、望诊助手读取相册
    </div>
    <div className="text-item">
      2．权限名称：Privacy - Camera Usage Description(NSCameraUsageDescription)
    </div>
    <div className="text-item">
      权限描述：相机(摄像头)访问权限
    </div>
    <div className="text-item">
      使用场景和目的：望诊助手拍照、客服沟通时拍照上传
    </div>
    <div className="text-item">
      3．权限名称：Privacy - Microphone Usage Description(NSMicrophoneUsageDescription)
    </div>
    <div className="text-item">
      权限描述：录音(麦克风)访问权限
    </div>
    <div className="text-item">
      使用场景和目的：用于语音输入识别文字
    </div>
    <div className="text-item">
      4．权限名称：UNAuthorizationOptions
    </div>
    <div className="text-item">
      权限描述：允许系统推送权限
    </div>
    <div className="text-item">
      使用场景和目的：允许用户接受app的系统推送（客服消息、内容推送）
    </div>
    <div style={{marginTop: "16px"}} className="text-item highlight">
      知源经络穴位鸿蒙应用权限列表：
    </div>
    <div className="text-item">
      1．权限名称：ohos.permission.APP_TRACKING_CONSENT
    </div>
    <div className="text-item">
      权限描述：允许应用读取开放匿名设备标识符
    </div>
    <div className="text-item">
      使用场景和目的：获取设备信息用于生成脱敏的终端用户设备标识，以提供统计分析服务
    </div>
    <div className="text-item">
      2．权限名称：ohos.permission.CAMERA
    </div>
    <div className="text-item">
      权限描述：允许应用使用相机
    </div>
    <div className="text-item">
      使用场景和目的：望诊助手拍照、客服沟通时拍照上传
    </div>
    <div style={{marginTop: "16px", fontSize: "18px", fontWeight: "bold", color: "#000"}}>
      九、第三方SDK接入信息
    </div>
    <div className="text-item highlight">
      1．第三方信息共享服务商清单
    </div>
    <div className="text-item highlight">
      我们接入的部分第三方SDK或类似应用程序可能会收集您的个人信息，如您在我们的服务中使用这类由第三方提供的服务时，您同意将由其直接收集和处理您的信息。我们会评估这类第三方服务收集个人信息的合法性、正当性、必要性，要求该些第三方对您的个人信息采取保护措施，并严格遵守相关法律法规与监管要求。您可以点击<a
      style={{color: "#70b1e7"}}
      href="https://sdk-ref.lighttcm.com/h5/sdk?key=pSEbtCbz5eU0%2F2Qy4D3S1cwmocPz46rZZRdk8cDpcPw%3D"
      target="_blank">《第三方信息共享清单》</a>，了解我们主要接入的第三方SDK基本情况，各单项服务接入第三方SDK的情况可能有不同，如需了解具体单项服务接入第三方SDK的详细情况，请您前往对应的第三方服务页面。
    </div>
    <div style={{marginTop: "16px"}} className="text-item highlight">
      <div>2.自启动和关联启动说明</div>
      <div className="highlight">为提升消息的送达率，及时地为您进行消息提醒，我们会默认为您开启关联启动功能，以保持应用的活跃度。如您不想开通此功能，我们建议您手动进行关闭，一般关闭路径：设置 - 应用 -
        应用启动管理 - 选择应用“知源经络穴位”- 关闭关联启动功能。
      </div>
    </div>
    <div style={{marginTop: "16px", fontSize: "18px", fontWeight: "bold", color: "#000"}}>
      十、本政策如何更新
    </div>
    <div className="text-item">
      鉴于产品形态变化、法律法规修订的影响因素，我们可能会不定期对本政策进行修订，同时，我们会在本页面上发布对本政策所做的任何变更。
    </div>
    <div className="text-item">
      如您对修订后的隐私政策有异议，您应及时停止使用我们的相关服务。若您同意新修订的政策或继续使用我们的服务，则表示您已经充分阅读、理解并同意我们的政策。
    </div>
    <div style={{marginTop: "16px", fontSize: "18px", fontWeight: "bold", color: "#000"}}>
      十一、如何联系我们
    </div>
    <div className="text-item">
      如果您对本隐私政策有任何疑问、意见或建议，您可以将相关内容发送至：
      <span className="highlight">zj@nineton.cn</span>；我们收到您的问题后，
      <span className="highlight">将在核实您的身份后的十五个工作内予以处理对您反馈的问题进行处理并及时向您答复</span>。
    </div>
    <div className="text-item">
      开发者名称：<span className="highlight">重庆知源健康管理有限公司。</span>
    </div>
    <div className="text-item">
      开发者地址：<span className="highlight">重庆市渝北区洪湖西路24号B栋11楼。</span>
    </div>
  </div>
}
